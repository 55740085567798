<template>
  <div>
    <div
      class="w-full grid h-96 justify-item-center font-extrabold text-white text-center content-center"
      :style="{
        backgroundImage: 'url(/Cover-Offroad-Safari.jpg)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backdropFilter: 'blur(10px)',
      }"
    >
      <h1 class="text-8xl">BLOG</h1>
    </div>
    <div class="container py-8 item-center mx-auto pt-4 pb-6" id="news">
      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 p-4 py-8">
        <div
          v-for="(sliderN, sliderIdx) in dataList"
          :key="sliderIdx"
          class="col-span overflow-hidden"
        >
          <div class="relative">
            <img
              :src="sliderN.image"
              :alt="sliderN.title"
              class="object-cover h-64 w-full hover:scale-125"
            />
            <div
              class="absolute bottom-0 left-0 py-4 px-4 text-white bg-gray-800 bg-opacity-25 w-full"
            >
              <span class="my-2 text-sm">{{
                $moment(sliderN.created_at).format("MMMM Mo, YYYY")
              }}</span>
              <h3 class="text-xl break-all font-bold truncate">
                {{ sliderN.title }}
              </h3>
              <span
                class="mt-4 text-sm hover:underline cursor-pointer"
                @click="openDetailPage(sliderN)"
                >Read More</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Slugify } from "@/utils";

export default {
  name: "BlogList",
  components: {},

  data() {
    return {
      dataList: [],
    };
  },

  computed: {},

  async created() {
    this.$store.commit("setOverlayLoading", true);

    try {
      const response = await this.$http.get(`${this.$apiTripweWeb}/blog/list`, {
        params: {
          type: 1,
        },
      });

      const data = response.data;
      this.dataList = data.data;
    } catch (error) {
      this.$toasted.global.error(error);
    } finally {
      this.$store.commit("setOverlayLoading", false);
    }
  },

  methods: {
    openDetailPage(detail) {
      this.$router.push({
        name: "BlogDetail",
        params: { slug: Slugify(detail.title), detail },
      });
    },
  },
};
</script>
